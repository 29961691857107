#addWorklog #worklogDetails #addProject #addUser .modal-body{
    overflow-y: auto !important;
    max-height: 79vh !important;
}
.modal.show .modal-dialog{
    max-width: 50% !important;
}

@media screen and (min-width: 300px) and (max-width: 500px){
    .modal.show .modal-dialog{
        max-width: 100% !important;
    }
}
.modal-title{
    width: 100%;
}
