.adminTable{
    padding-left: 20px;
    padding-right: 20px;
    overflow: hidden;
    padding-bottom: 20px;
}
.adminDatePicker{
    padding-top: 2%;
    padding-bottom: 20px;
}
.ReactTable .rt-thead.-header{
    padding: 15px !important;
    color: 	#505050;
    font-weight: bold;  
    font-size: 0.9rem;
}
.ReactTable .rt-tbody{
    padding: 15px !important;
   
}
.rs-col{
    width: 20% !important;
}
.rs-input-group.rs-input-group-inside{
    background-color: #DCDCDC !important;
}
.rs-input{
    background-color: #DCDCDC !important;
}
#downloadIcon{
    width: 40px;
    height: 40px;
    fill: 	#D3D3D3;
    padding-bottom: 8px;
}
#userProfileIcon{
    width: 50px;
    height: 50px;
    fill: white;
}
.react-datepicker__input-container input{
    width: 100% !important;
    border: 1px solid #bebebe !important;
}
/* .react-datepicker__input-container input:focus{
    color: #686868 !important;
    border: 2px solid #004299 !important;
    font-size: small !important;
    width: 31vh !important;
} */
input:focus:not(.focus-visible) {
    outline: 0;
    /* width: 31vh; */
}
.react-datepicker-wrapper{
    width: auto !important;
    display: block !important;
}
.react-datepicker-popper{
    left: 30px !important;
}
.dropdownComp .btn-close{
    background: none !important;
}
input[type="text"]{
    font-size: small !important;
    height: 29px;
} 
.editViewIcons svg{
    width: 22px;
    height: 22px;
}
#resendImg{
    width: 2.5rem;
    height: 2rem;
    cursor: pointer;
}
#checkIcon{
    width: 25px;
    height: 25px;
    cursor: pointer;
}
.css-hsi95o-MuiTableRow-root td{
    /* padding-bottom: 0; */
}
.addBtn .rs-col {
  width: 40% !important;
}
.ReactTable .rt-tbody .rt-td{
    text-align: center;
}
.worklogTable{
   height: 55vh;
}
.ReactTable .rt-tfoot .rt-td{
    text-align: center !important;
}
.ReactTable .rt-tbody{
    overflow: hidden !important;
}
.sticky_col{
    color: red !important;
}
.bg-gray .rs-calendar-table-cell-day{
 color: red !important;
}
.rs-calendar-table-col{
    background-color: #505050 !important;
}
.rs-picker-popup{
    z-index: 999;
}
.rs-calendar-table-cell .rs-calendar-table-cell-week-day{
    color: rebeccapurple;
}
.ReactTable .rt-tbody{
    padding: 0 !important;
}
.ReactTable .rt-thead.-header{
    height: 50px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    padding: 0 !important;
}
.ReactTable .rt-thead .rt-resizable-header-content {
  padding-top: 5px !important;
}
.ReactTable .rt-resizer{
   z-index: 0 !important;
}
.download{
    padding-left: 0px !important;
    margin-top: 25px !important;
  }
  .addBtn{
    display: flex !important;
    justify-content: end !important;
    padding: 0 !important;
    margin-top: 20px !important;
    margin-bottom:  4px !important;
    height: auto !important;
  }
@media screen and (min-width: 375px) and (max-width: 500px){
  .download{
    padding-left: 10px !important;
    margin-top: 10px !important;
  }
  .addBtn{
    display: flex !important;
    justify-content: end !important;
    padding: 0 !important;
    margin-top: 20px !important;
    margin-bottom:  4px !important;
    height: auto !important;
  }
}
.worklogInput.mb-3{
    margin-bottom: 0 !important;
}
.react-datepicker-popper {
    /* position: static!important;
    transform: none!important; */
  }
  .react-switch-bg{
    height: 18px !important;
    width: 46px !important;
  }
  .react-switch-handle{
    height: 16px !important;
    width: 16px !important;
  }
  .react-switch-bg svg{
    height: 19px !important;
    width: 35px !important;
  }
  .ReactTable .rt-tbody .rt-td:hover {
    overflow: visible;
    white-space: unset;
    word-break :break-all;
}
/* .rthfc.-se .-header .rt-th.rthfc-th-fixed{
  width: 5% !important;
}

.rthfc.-se .-header .rt-th.rthfc-th-fixed, .rthfc.-se .-headerGroups .rt-th.rthfc-th-fixed, .rthfc.-se .-filters .rt-th.rthfc-th-fixed, .rthfc.-se .rt-td.rthfc-td-fixed{
  width: 5% !important;
} */
    
