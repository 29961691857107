@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

/* body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #fcfcfc;
} */

.container1 {
  /* width: 1400px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex !important;
  justify-content: space-between;
  align-items: baseline;
  height: 100%;
  flex-direction: column; */
  padding: 20px;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.container2{
    align-items: center;
    height: 100%;
    width: 700px;
    justify-content: end;
    color: white;
    display: flex;
    float: right;
    padding-top: 15px;
}
.navbar {
  height: 60px;
  background-color: rgb(76,79,82);
  position: relative;
}

.logo {
    color: white;
}

.menu-icon {
  display: none;
}

.nav-elements {
    align-items: center;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: white !important;
 
  text-decoration: none;
}
.nav-elements ul a:hover {
  font-size: 16px;
  font-weight: 400;
  color: white !important;
 
  text-decoration: none;
}
.nav-elements ul a.active {
  color: white;
  font-weight: 400;
  position: relative;
  font-weight: bold;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #dcbdbd !important;
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: rgb(76,79,82);
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}
@media screen and (max-width: 768px) {
  .jUpdPU {
    display: none !important;
  }
  .ibyFvV {
    display: flex !important;
  }
  #abc.gGULvO {
    display: none !important;
  } 
}
.btn-group{
  background-color: #A9A9A9 !important;
}
