.projectDetails{
    padding: 20px;
    height: 90vh;
    display: flex;
    flex-direction: column;
}
.projectLabel{
    background-color: white;
    margin: 10px;
    display: flex;
    align-items: center;
}
.createProjectBtn{
	margin-right: 2%;
	float: right;
    color: white !important;
    padding-bottom: 10px;
    position: relative;
}
.form-label{
    font-size: small !important;
    font-weight: bold !important;
    margin-bottom: 0 !important;
    color: #686868 !important;
}
.form-control{
    font-size: small !important;
}
.dropdown-item{
    font-size: smaller !important;
}
.was-validated .form-control:invalid, .form-control.is-invalid{
    margin-bottom: 0 !important;
}
.invalid-feedback{
    font-size:small !important;
}
#userDropdown.dropdown-menu.show{
    height: 100px !important;
}
.rbt-aux button{
    display: flex;
    align-items: center;
    bottom: 0;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    background-color: transparent;
    border: 0;
}
.rbt-token-active{
    background-color: #545cd8 !important;
    color: white;
    outline: none;
    text-decoration: none;
}
.rbt-token .rbt-token-remove-button{
    background-color: #e0f3ff !important;
    border: 0 !important;
    border-radius: .25rem !important;
    color: #545cd8 !important;
    display: inline-block !important;
    line-height: 1em !important;
    margin: 0 3px 3px 0 !important;
    padding: 4px 7px !important;
    position: relative !important;
}
.rbt-token-removeable{
    padding-right: 0 !important;
}
.css-tucewo{
    gap: 0.5rem !important;
}
/* .projectLabel h4{
    font-weight: bold;
    padding-bottom: 15px;
    align-items: center;
} */