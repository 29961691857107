.css-1r4vtzz{
    background-color:#DCDCDC !important;
   width: 385px !important;
}
.css-48ayfv{
    background-color:#DCDCDC !important;
   width: 400px !important; 
}
/* .css-1nmdiq5-menu {
    top: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
    background-color: hsl(0, 0%, 100%);
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
    margin-bottom: 8px;
    margin-top: 8px;
    box-sizing: border-box;
} */
.css-1vr111p-option{
 min-width: 200px !important;
}
.css-1vr111p-option:active {
    min-width: 200px !important;
}
.css-1jllj6i-control{
    display: none !important;
}
.css-1qprcsu-option{
    min-width: 200px !important;
}