
.container {
  width: 100%;
  max-width: 800px;
  padding: 0 10px;
  margin: 0 auto;
  margin-top: 70px;
}

.title {
  text-align: center;
  font-size: 26px;
  margin-bottom: 30px;
}
table{
    width: 100% !important;
}
.inputTable table {
  border-collapse: collapse;
 
}

.inputTable tr th {
  font-size: 12px;
  padding: 8px;
  border: 1px solid #eeeeee;
  text-align: left;
  background-color: rgba(217, 221, 146, 0.2);
}

.inputTable tr td {
  border: 1px solid #eeeeee;
  text-align: left;
  font-size: 12px;
  padding-left: 5px;
}

.tableInput input {
  font-size: 14px;
  background-color: transparent;
  border: none;
  width: 91%;
  padding: 12px 12px;
}

.tableInput input:hover {
  background-color: #fffaf3;
}

.tableInput input:focus {
  outline: 1px solid #ccc;
  border: 1px solid #ccc;
}
.react-datepicker-popper{
    z-index: 999 !important;
}