
.holidaysContainer{
    padding-left: 20px;
    padding-right: 20px;
    overflow: hidden;
    padding-bottom: 20px;
    max-height: 100vh !important;
}
.holidayTable table {
    border-collapse: collapse;
    padding: 10px;
  }
  .holidayTable tbody{
    overflow-y: auto !important;
    max-height: 500px !important;
  }
  .holidayTable tr th {
    font-size: 16px;
    padding: 8px;
    border: 1px solid #eeeeee;
    text-align: left;
    background-color: rgba(217, 221, 146, 0.2);
  }
  
  .holidayTable tr td {
    border: 1px solid #eeeeee;
    text-align: left;
    font-size: 14px;
    padding: 10px;
  }
  .userTab{
    overflow: auto;
    max-height: 60vh;
  }