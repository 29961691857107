.login{
   
  
}
.container-fluid{
    background-image: url("./event-time.jpg");
    max-width: 100% !important;
    height: 100vh !important;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    /* filter: blur(4px); */
}
label {
    font-size: 1rem !important;
    color: rgb(61, 58, 58) !important;
}
.was-validated .form-control:invalid{
    padding-right: 0 !important
}
.was-validated .form-control:valid, .form-control.is-valid{
    padding-right: 0 !important
}
.invalid-feedback{
    margin-top: 0;
}
#forgotPass{
    max-width: 37% !important;
}
.forgotPassform .invalid-feedback{
  position: relative !important;
}
.inputBox {
    width: 85%;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.19);
    border-radius: 0.3rem;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.6rem;
  }
  .inputField {
    width: 100%;
    background-color: transparent;
    appearance: none;
    border: none;
    outline: none;
  }
.password {
    width: 80% !important;
  }
  
  #icon {
    position: absolute;
    right: 12px;
    top: 176px;
    width: 20px;
    height: 22px;
    margin-right: 43px;
  }
/* .form-outline .form-control:focus~.form-notch .form-notch-leading {
    border-top: .125rem solid #00B74A;
    border-bottom: .125rem solid #00B74A;
    border-left: .125rem solid #00B74A;
}
.form-outline .form-control:focus~.form-notch .form-notch-middle {
    border-bottom: .125rem solid;
    border-color: #00B74A;
}
.form-outline .form-control:focus~.form-notch .form-notch-trailing {
    border-color: currentcolor currentcolor currentcolor #00B74A;
    border-bottom: .125rem solid #00B74A;
    border-right: .125rem solid #00B74A;
    border-top: .125rem solid #00B74A;
} */
