.css-1p823my-MuiListItem-root{
    border-bottom: 1px solid rgb(218, 206, 206) !important;
}
.css-1p823my-MuiListItem-root:hover{
    background: aliceblue !important;
}
#pencilIcon{
    width: 25px;
    height: 25px;
    margin-right: 10px;
}
#deleteIcon{
    width: 25px;
    height: 25px;
}
.css-1yo8bqd{
    border-bottom: 1px solid rgb(218, 206, 206) !important;
}
.css-1yo8bqd:hover{
    background: aliceblue !important;
}
.css-15v22id-MuiAccordionDetails-root{
    padding: 0 !important;
}
.css-konndc-MuiListItemText-root{
    margin-top: 0 !important;
    margin-bottom: 0 !important
}
.css-eqpfi5-MuiAccordionSummary-content{
    font-weight: bold;
    font-size: medium;
}
.css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded{
    min-height: 0px !important;
   margin: 0 !important;
   background-color: #F0F0F0 !important;
   padding: 0px, 9px !important;
}
.css-10hburv-MuiTypography-root{
    font-size: 12px !important;
}
.css-83ijpv-MuiTypography-root{
    font-size: 12px !important;
}
.css-bshv44-MuiButtonBase-root-MuiListItem-root{
    padding-left: 14px !important;
    padding-top: 0 !important;
    padding-right: 10 !important;
    padding-bottom: 10 !important;
    border: 1px solid rgb(236, 229, 229) !important;
}
.css-eqpfi5-MuiAccordionSummary-content.Mui-expanded{
    margin-bottom: 10px !important;
    margin-top: 10px !important;
    margin-left: 0px !important;
}
.css-l0jafl.Mui-expanded{
    min-height: 0px !important;
}
.css-l0jafl.Mui-expanded{
  margin: 0 !important;
}
.css-u7qq7e{
    padding: 0 !important;
}
.css-1xar93x{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
.css-yb0lig{
    font-size: 12px !important;
}
.css-mbfek{
    font-size: 12px !important;
}
.css-1yo8bqd{
    padding-left: 14px !important;
    padding-top: 0 !important;
    padding-right: 10 !important;
    padding-bottom: 10 !important;
}
.css-1oqimao.Mui-expanded{
    background-color: #F0F0F0 !important;
}
.text{
    text-overflow: ellipsis;  
    /* margin-bottom: 8px; */
    cursor: pointer;       
    word-break: break-all;
    overflow:hidden;     
    width: 340px;
    white-space: nowrap;
    margin-right: 30px;
}
.text:hover{
    overflow: visible;      
    white-space: normal;
    height:auto;
}