.loader{
    margin-top: 4%;
    width: 5rem !important;
    height: 5rem !important;
}
.confirm{
    margin-top: 14%;
    margin-left: 4%;
    text-align: center;
}
.label{
    margin-bottom: 2%;
    margin-top: 1%;
}
.reset{
    padding-top: 14%;
}
.reset .modal-body{
    overflow-x: hidden !important;
}
.editUserBtn{
	margin-right: 2%;
	float: right;
  margin-bottom: 1rem;
}
.backLogin{
    text-align: center;
    margin-bottom: 1rem;
}